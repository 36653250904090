import React, { useState, Fragment } from "react";
import { Link, navigate } from "gatsby";
// import HeaderAd from "./ads/header-ad";
import Logo from "./logo";
import AuthService from "../services/auth.service";
import { AuthContext } from "../context/auth-context";
import AuthPopUp from "./auth-popup";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";

const navigation = [
  { label: "Home", link: "/" },
  { label: "Rules", link: "/rules" },
  { label: "History of Word Games", link: "/history" },
  { label: "Contact Us", link: "/contact" },
  // { label: "Privacy Policy", link: "/privacy-policy" },
];
const profile = [];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NavBar({ isHomePage, title }) {
  const { user, setUser, setProfile } = React.useContext(AuthContext);
  const [signIn, setSignIn] = useState(false);

  const logOut = () => {
    setUser(null);
    setProfile(null);
    AuthService.logout();
    navigate("/");
  };

  return (
    <>
      <div>
        <Disclosure as="nav" className="fixed top-0 right-0 left-0 bg-gray-800">
          {({ open }) => (
            <>
              <div className="w-full px-4  ">
                <div className="flex items-center justify-between h-16">
                  <div className="flex items-center">
                    <div className="flex-shrink-0 text-white">
                      <Link to={"/"}>
                        <Logo />
                      </Link>
                    </div>
                    <div className="hidden lg:block">
                      <div className="ml-10 flex items-baseline space-x-4">
                        {navigation.map((item, itemIdx) => (
                          <Link
                            key={itemIdx}
                            to={item.link}
                            className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                          >
                            {item.label}
                          </Link>
                        ))}
                        {user !== null ? (
                          <Link
                            to={"/my-games"}
                            className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                          >
                            My Games
                          </Link>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  {user !== null ? (
                    <div className="hidden lg:block">
                      <div className="ml-4 flex items-center lg:ml-6">
                        {/* Profile dropdown */}
                        <Menu as="div" className="ml-3 relative">
                          {({ open }) => (
                            <>
                              <div>
                                <Menu.Button className="text-white max-w-xs bg-gray-800  flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                                  <span className="sr-only">
                                    Open user menu
                                  </span>
                                  <MenuIcon
                                    className="block h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </Menu.Button>
                              </div>
                              <Transition
                                show={open}
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items
                                  static
                                  className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                >
                                  {profile.map((item) => (
                                    <Menu.Item key={item}>
                                      {({ active }) => (
                                        <Link
                                          to={item.link}
                                          className={classNames(
                                            active ? "bg-gray-100" : "",
                                            "block px-4 py-2 text-sm text-gray-700"
                                          )}
                                        >
                                          {item.label}
                                        </Link>
                                      )}
                                    </Menu.Item>
                                  ))}

                                  <Menu.Item>
                                    <button
                                      className={
                                        "block px-4 py-2  w-full text-left"
                                      }
                                      onClick={() => logOut()}
                                    >
                                      Sign Out
                                    </button>
                                  </Menu.Item>
                                </Menu.Items>
                              </Transition>
                            </>
                          )}
                        </Menu>
                      </div>
                    </div>
                  ) : (
                    <div className=" block">
                      <div className="ml-4 flex items-center lg:ml-6">
                        <button
                          onClick={() => setSignIn(true)}
                          className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium"
                        >
                          Login / Sign Up
                        </button>
                      </div>
                    </div>
                  )}
                  <div className="-mr-2 flex lg:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="bg-gray-800 inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon
                          className="block h-6 w-6"
                          aria-hidden="true"
                        />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="lg:hidden">
                <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                  {navigation.map((item, itemIdx) => (
                    <Link
                      key={`${itemIdx}-m`}
                      to={item.link}
                      className="text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                    >
                      {item.label}
                    </Link>
                  ))}
                </div>
                <div className="pt-4 pb-3 border-t border-gray-700">
                  <div className=" px-2 space-y-1">
                    {user !== null ? (
                      <Link
                        className={
                          "block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                        }
                        to={"/my-games"}
                      >
                        My Games and Summary
                      </Link>
                    ) : null}
                    {user !== null ? (
                      <button
                        className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                        onClick={() => logOut()}
                      >
                        Sign Out
                      </button>
                    ) : (
                      <button
                        className="block px-3 py-2 rounded-md text-base font-medium text-gray-400 hover:text-white hover:bg-gray-700"
                        onClick={() => setSignIn(true)}
                      >
                        Sign In / Register
                      </button>
                    )}
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>

        {/* <header className="bg-white shadow">
          <div className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <h1 className="text-3xl font-bold text-gray-900">Dashboard</h1>
          </div>
        </header>
        <main>
          <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
            
            <div className="px-4 py-6 sm:px-0">
              <div className="border-4 border-dashed border-gray-200 rounded-lg h-96" />
            </div>
           
          </div>
        </main> */}
      </div>
      {/* <div
        className={
          "container mx-auto font-sans px-4 text-white text-center py-6 "
        }
      > 
        <div className={"mt-4"}>
          <HeaderAd />
        </div>
      </div> */}
      {signIn && <AuthPopUp setSignIn={setSignIn} />}
      {/* <SignIn /> */}
    </>
  );
}
