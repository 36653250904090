import React from "react";
import GameService from "../services/game.service";
export const isBrowser = () => typeof window !== "undefined";
 

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = React.useState(false);
  const [activeCat, setActiveCat] = React.useState(null);
  const [profile, setProfile] = React.useState(false);
  const [loadingProfile, setLoadingProfile] = React.useState(false);

  // const rawSetAuth = () => {
  //   localStorage.setItem("user", JSON.stringify(user));
  // };

  React.useEffect(
    (_) => {
      const rawSetAuthUseEffect = () => {
        localStorage.setItem("user", JSON.stringify(user));
      };
      if (user !== false) {
        rawSetAuthUseEffect();
      }
    },
    [user]
  );

  React.useEffect(
    (_) => {
      const updateProfile = async () => {
        const updatedProfile = await GameService.returnMySummary({
          tally: true,
        });
        if (!updatedProfile) { 
          setProfile(null);
          setUser(null);
          setLoadingProfile(false);
        } else { 
          setProfile(updatedProfile.result);
          localStorage.setItem("profile", JSON.stringify(updatedProfile.result));
          setLoadingProfile(false);
        }
      };
      if (user !== null && profile === null && loadingProfile === false) {
        setLoadingProfile(true); 
        updateProfile();
      }
    },
    [user]
  );

  React.useEffect(
    (_) => { 
      const rawSetProfileUseEffect = () => {
        localStorage.setItem(
          "profile",
          profile === null ? null : JSON.stringify(profile)
        );
      };
      if (profile !== false) {
        rawSetProfileUseEffect();
      }
    },
    [profile, user]
  );

  React.useEffect(() => {
    const updateUser = async () => {
      const storedPrefs =
        isBrowser() && window.localStorage.getItem("user")
          ? await window.localStorage.getItem("user")
          : null;
      if (typeof storedPrefs === "string") {
        setUser(JSON.parse(storedPrefs));
        return JSON.parse(storedPrefs);
      }
      setUser(null);
      return [];
    };
    updateUser();

    const updateProfile = async () => {
      const storedPrefs =
        isBrowser() && window.localStorage.getItem("profile")
          ? await window.localStorage.getItem("profile")
          : null;
      if (typeof storedPrefs === "string") {
        setProfile(JSON.parse(storedPrefs));
        return JSON.parse(storedPrefs);
      }
      setProfile(null);
      return [];
    };
    updateProfile();
  }, []);

  return (
    <AuthContext.Provider
      value={{ profile, setProfile, user, setUser, activeCat, setActiveCat }}
    >
      {children}
    </AuthContext.Provider>
  );
};
