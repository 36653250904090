import axios from "axios";

const API_URL = process.env.GATSBY_NODE_API;

class AuthService {
  login(email, password) {
    return axios
      .post(API_URL + "api/user/login", {
        email,
        password,
      })
      .then((response) => {
        if (response.data.token) {
          localStorage.setItem("user", JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  logout() {
    localStorage.removeItem("user");
  }

  register(email, password) {
    return axios
      .post(API_URL + "api/user/register", {
        email,
        password,
      })
      .catch((err) => {
        console.log("There was an error", err);
        return { status: "failed" };
      });
  }
  requestPasswordToken = async (email) => {
    console.log("Gt here", email);
    return axios
      .post(`${API_URL}api/user/forgot`, {email})
      .then((response) => {
        console.log(response);
        if (response.error === null) {
          return response.data;
        } else {
          return response.data;
        }
      })
      .catch((err) => {
        console.log("There was an error", err);
        return { status: "failed" };
      });
  };
  facebookLogin = async () => { 
    return axios
      .get(`${API_URL}api/user/facebook`)
      .then((response) => {
        console.log(response);
        if (response.error === null) {
          return response.data;
        } else {
          return response.data;
        }
      })
      .catch((err) => {
        console.log("There was an error", err);
        return { status: "failed" };
      });
  };
  facebookCallback = async ({data}) => { 
    return axios
      .post(`${API_URL}api/user/facebook/validate`, data)
      .then((response) => {
        console.log(response);
        if (response.error === null) {
          return response.data;
        } else {
          return response.data;
        }
      })
      .catch((err) => {
        console.log("There was an error", err);
        return { status: "failed" };
      });
  };
  checkPasswordToken = async (token) => {
    console.log("Gt here", token);
    return axios
      .get(`${API_URL}api/user/reset/${token}`)
      .then((response) => {
        console.log(response);
        if (response.error === null) {
          return response.data;
        } else {
          return response.data;
        }
      })
      .catch((err) => {
        console.log("There was an error", err);
        return { status: "failed" };
      });
  };
  updatePasswordToken = async (token, password) => {
    console.log("Gt here", token);
    return axios
      .post(`${API_URL}api/user/reset/${token}`, { password })
      .then((response) => {
        console.log(response);
        if (response.error === null) {
          return response.data;
        } else {
          return response.data;
        }
      })
      .catch((err) => {
        console.log("There was an error", err);
        return { status: "failed" };
      });
  };

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("user"));
  }
}

export default new AuthService();
