import React from "react"; 
import NavBar from "./nav-bar"
import { AuthProvider } from "../context/auth-context"; 

export default function Header({ isHomePage, title }) {
  return (
    <AuthProvider>
      <NavBar isHomePage={isHomePage}  title={title} />
    </AuthProvider>
  );
}
