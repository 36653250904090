import React from "react";
import SignIn from "./sign-in";
import SignUp from "./sign-up";

function AuthPopUp({ setSignIn }) {
  return (
    <div
      className={
        "fixed z-50 bg-white top-0 bottom-0 right-0 left-0 overflow-y-scroll lg:flex items-center"
      }
    >
        <div className={'container mx-auto px-4'}>
            <div className={'w-100 grid grid-cols-1 lg:grid-cols-2 gap-4'}>
                <div><SignIn setSignIn={setSignIn} /></div>
                <div><SignUp setSignIn={setSignIn} /></div>
            </div>
            <div className={'w-100  text-center'}>
                <button onClick={() => setSignIn(false)}>Cancel</button>
            </div>
        </div>
      
    </div>
  );
}

export default AuthPopUp;
