import React from "react";
// import { Link } from "gatsby";
import HeaderAd from "./ads/header-ad";

export default function Footer({ isHomePage, title }) {
  return (
    <div
      className={
        "container mx-auto font-sans pb-4 px-4 text-xs text-gray-700 py-6 text-center lg:text-left"
      }
    >
      <div className={"mt-4"}>
        <HeaderAd />
      </div>
      <div className={"grid grid-col-1 lg:grid-col-2 lg:gap-4 mt-20 mb-8"}>
        <div className={'w-100 text-center'}>
          A word game by{" "}
          <a href="http://nicklarosa.net" target="_new">
            Nick La Rosa
          </a>
        </div>
        <div></div>
      </div>
    </div>
  );
}
