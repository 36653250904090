import React from "react";
// import { Link } from "gatsby";
const heading = ["H", "A", "N", "G", "R"];
export default function Logo({ isHomePage, title }) {
  return (
    <div className={"grid grid-cols-5 mx-auto"}>
      {heading.map((letter, i) => (
        <div
          key={`logo${i}`}
          className={`w-8 h-8 text-xl hover:text-white hover:bg-blue-500 text-black border-2 border-black bg-white flex justify-center items-center transform skew-x-12`}
        >
          {letter}
        </div>
      ))}
    </div>
  );
}
