import React, { useState } from "react";
import AuthService from "../services/auth.service";

function SignUp() {
  const [userData, setUserData] = useState({ email: "", password: "" });
  const [complete, setComplete] = useState(false)
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const handleInputChange = (e) => { 
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSignUp = (e) => {
    e.preventDefault();
    AuthService.register(userData.email, userData.password).then(
      (data) => { 
        if( data.data && data.data.message === "Signup successful"){ 
          setComplete(true)
        } else {
          setErrorMessage("There was en error. Has this email address been used before?")
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
            console.log("There was an error", error.message)
        setLoading(false);
        setErrorMessage(resMessage);
      }
    );
  };

  return (
    complete === false ?
    <div className="SignUp">
      <h3 className={"font-bold text-xl"}>Sign Up</h3>
      <form onSubmit={handleSignUp}>
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col">
          <div className="mb-4">
            <label
              className="block text-grey-darker text-sm font-bold mb-2"
              htmlFor="email"
            >
              Email Address
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
              name="email"
              id="email"
              type="email"
              value={userData.email}
              placeholder="email"
              onChange={(e) => handleInputChange(e)}
            />
          </div>
          <div className="mb-6">
            <label
              className="block text-grey-darker text-sm font-bold mb-2"
              htmlFor="password"
            >
              Password
            </label>
            <input
              className="shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-darker mb-3"
              name="password"
              password="password"
              type="password"
              value={userData.password}
              placeholder="password"
              onChange={(e) => handleInputChange(e)}
            />
          </div>
          {errorMessage && <p className={'text-red-900 mb-4'}>{errorMessage}</p>}
          <div className="flex items-center justify-between">
            <button
              className="bg-blue-900 hover:bg-blue-dark text-white font-bold py-2 px-4 rounded"
              type="submit"
            >
              Sign In
            </button>
            {loading === true && <p>Loading</p>}
          </div>
        </div>
      </form>
    </div> : <div>Sign up complete, please use the login fields to sign in.</div>
  );
}

export default SignUp;
