import React, { useState } from "react";
import {Link, navigate} from 'gatsby'
import AuthService from "../services/auth.service";
// import { Redirect } from "react-router-dom";
import { AuthContext } from "../context/auth-context";
// import FacebookLogin from 'react-facebook-login';

function SignIn({ setSignIn }) {
  const {  setUser } = React.useContext(AuthContext);
  const [userData, setUserData] = useState({ email: "", password: "" }); 
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false); 
  const handleInputChange = (e) => { 
    setUserData({
      ...userData,
      [e.target.name]: e.target.value,
    });
  };
 

  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    AuthService.login(userData.email, userData.password).then(
      (result) => {
        if (result.token) {
          setUser({ token: result.token });
          // setLoggedIn(true);
          setSignIn(false)
          navigate("/")
        }
      },
      (error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        console.log("There was an error", resMessage);
        setLoading(false);
        setErrorMessage(resMessage);
      }
    );
  };

  return (
    <div className="Login container mx-auto py-4">
      <h3 className={"font-bold text-xl"}>Sign In</h3>
      <form onSubmit={handleLogin}>
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col">
          <div className="mb-4">
            <label
              className="block text-grey-darker text-sm font-bold mb-2"
              htmlFor="email"
            >
              Email Address
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
              name="email"
              id="email"
              type="email"
              value={userData.email}
              placeholder="email"
              onChange={(e) => handleInputChange(e)} 
            />
          </div>
          <div className="mb-6">
            <label
              className="block text-grey-darker text-sm font-bold mb-0"
              htmlFor="password"
            >
              Password
            </label>
            <input
              className="shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-darker mb-0"
              name="password"
              password="password"
              type="password"
              value={userData.password}
              placeholder="password"
              onChange={(e) => handleInputChange(e)}
            />
          </div>
          <p className={"text-sm mb-3"}><Link to="/forgot-password" onClick={() => setSignIn(false)}>Forgot your password?</Link></p>
          {errorMessage && <p className={'text-red-900 mb-4'}>{errorMessage}</p>}
          {loading === true && <p className={'text-red-900 mb-4'}>Loading</p>}
          <div className="flex items-center justify-between">
            <button
              className="bg-blue-900 hover:bg-blue-dark text-white font-bold py-2 px-4 rounded"
              type="submit"
            >
              Sign In
            </button> 
          </div>
        </div>
      </form>
      {/* <button onClick={() => handleFacebookLogin()}>Facebook SIgn In</button>

      <FacebookLogin
        appId={process.env.GATSBY_FB_APP_ID}
        autoLoad={true}
        fields="id,name,email"
        onClick={handleFacebookLoginClicked}
        callback={handleFacebookLogin} /> */}
    </div>
  );
}

export default SignIn;
