import React from "react";
export default function HeaderAd( ) {
  return (
    <div className={" w-full  "}>
      <img
        className={"mx-auto"}
        src={"https://via.placeholder.com/728x90?text=728x90+Leaderboard"}
        alt={"Ad PLacehollder"}
      />
    </div>
  );
}
