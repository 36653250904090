import React from "react";
// import { Link, useStaticQuery, graphql } from "gatsby";
import Header from "./header";
import Footer from "./footer";
import "../styles/global.css";
import "../styles/animate.css";

const Layout = ({
  isHomePage,
  children,
  header = null,
  // classString = "container mx-auto font-sans px-4 py-4",
}) => {
 
  return (
    <div
      className="global-wrapper text-primary font-sans bg-gray-100 min-h-screen"
      data-is-root-path={isHomePage}
    >
      <Header isHomePage={isHomePage} title={"Hangman Gatsby"} />
      {header !== null ? header : null}
      <div className={header === null ? "container pt-20 mx-auto px-4 font-sans " : "container pt-0 mx-auto px-4 font-sans "}>
        <main>{children}</main>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
