import axios from "axios";

const API_URL = process.env.GATSBY_NODE_API;

class GameService {
  async returnMyGames({page = 1}) {
    const { token } = JSON.parse(localStorage.getItem("user")); 
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    return axios
      .get(`${API_URL}public/auth/my-games?page=${page}`, config)
      .then((response) => {
        return response.data;
      });
  }
  async returnMySummary({tally=true}) {
    let url;
    if(tally === true){
      url = `${API_URL}public/auth/my-summary`
    } else {
      url = `${API_URL}public/auth/saved-summary`
    }

    const { token } = JSON.parse(localStorage.getItem("user")); 
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    return axios
      .get(url, config)
      .then((response) => {
        return response.data;
      });
  }
  async returnWordAuth(slug) {
    const { token } = JSON.parse(localStorage.getItem("user")); 
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    return axios
      .get(`${API_URL}public/auth/word-finder/${slug}`, config)
      .then((response) => {
        return response.data;
      });
  }
  async returnWord(slug) {
    return axios
      .get(`${API_URL}public/word-finder/${slug}`)
      .then((response) => {
        return response.data;
      });
  }
  async endGameAuth({ _id, payload }) {
    const { token } = JSON.parse(localStorage.getItem("user")); 
    const config = {
      headers: {
        Authorization: `bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    return axios
      .put(`${API_URL}public/auth/game/${_id}`, payload, config)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });;
  }
  async endGame({ _id, payload }) {
    return axios
      .put(`${API_URL}public/game/${_id}`, payload)
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        return err;
      });
  }
}

export default new GameService();
